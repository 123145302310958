import React from 'react'
import "./Program.css"
import programsData from "../../data/ProgramData"

const Program = () => {
  return (
    <div className="Program" id='program'>
        {/* Header */}
        <div className="program-header">
            <span className='stroke-text'>Explore our</span>
            <span>Programs</span>
            <span className='stroke-text'>to shape you</span>
        </div>
        <div className="program-categories">
            {programsData.map((program)=>(
                <div className="category">
                    {program.image}
                    <span>{program.heading}</span>
                    <span> {program.details} </span>
                    <div className="join-now">
                        <span>Join Now</span>
                        <img src="images/rightArrow.png" alt="arrow" />
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default Program