import React from 'react'
import "./Reasons.css"

const Reasons = () => {
  return (
    <div className='Reasons' id='reasons'>
        <div className="left-r">
            <img src="images/image1.png" alt="" />
            <img src="images/image2.png" alt="" />
            <img src="images/image3.png" alt="" />
            <img src="images/image4.png" alt="" />
        </div>
        <div className="right-r">
          <span>some reasons</span>
          <div>
          <span className='stroke-text'>why</span>
          <span> choose us?</span>
          </div>

          <div className='details-r'>
              <div>
                 <img src="images/tick.png" alt="" />
                    <span> OVER 140+ EXPERT COACHES </span>
              </div>
              <div>
                 <img src="images/tick.png" alt="" />
                     <span> TRAIN SMARTER AND FASTER THAN BEFORE </span>
              </div>
              <div>
                <img src="images/tick.png" alt="" />
                   <span> 1 FREE PROGRAM FOR NEW MEMBER </span>
              </div>
              <div>
                 <img src="images/tick.png" alt="" />
                    <span> RELIABLE PARTNERS </span>
               </div>
          </div>
          <span
          style={{
            color:'var(--gray)',
            fontWeight: 'normal'
          }}>OUR PARTNERS
          </span>

          <div className='partners'>
            <img src="images/nb.png" alt="" />
            <img src="images/adidas.png" alt="" />
            <img src="images/nike.png" alt="" />
         </div>

        </div>
    </div>
  )
}

export default Reasons